import React from "react";
import { Icon } from "@iconify/react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const LogoTransition = ({ showModal, handleCloseTransitionModal }) => {
  const handleClose = () => {
    handleCloseTransitionModal();
  };
  const isExtraLargeOrAbove = useMediaQuery({ query: "(min-width: 1500px)" });

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <>
          <div
            className="d-none d-lg-flex justify-content-center align-items-center position-relative"
            style={{
              width: "100%",
              minHeight: "230px",
            }}
          >
            <div>
              <img
                src="link-preview-old.png"
                alt="Logo"
                style={{ width: "280px" }}
              />
              <Icon
                icon="ic:baseline-arrow-right-alt"
                width="65px"
                className="m-2"
                style={{ color: "#000" }}
              />
              <img
                src="link-preview.png"
                alt="Logo"
                style={{ width: "310px" }}
              />
            </div>
          </div>
          <div className="d-flex d-sm-flex d-lg-none flex-column align-items-center justify-content-center mb-3 ">
            <img
              src="link-preview-old.png"
              alt="Logo"
              // style={{ width: "160px", height: "120px" }}
              style={{ width: "35vh" }}
            />
            <h3 className="text-center my-3">is now</h3>

            <img
              src="link-preview.png"
              alt="Logo"
              // style={{ width: "200px", height: "150px" }}
              style={{ width: "40vh" }}
            />
          </div>
          <div className="card-header text-center justify-content-center mb-5">
            <h3 className="text-success">
              Fresh New Brand, Same Simplified Management
            </h3>
          </div>

          {/* <div className="d-grid gap-5 d-md-flex justify-content-end">
            <button
              className="btn text-success  btn-block fs-6 fw-bold "
              onClick={() => handleClose()}
            >
              Close
            </button>
          </div> */}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default LogoTransition;
