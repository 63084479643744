import { Icon } from "@iconify/react";
import { QuestionIcon } from "@primer/octicons-react";
import ReactTooltip from "react-tooltip";

export default function ComparisonPage() {
  const pricingData = [
    {
      Feature: "1 Loan",
      terraNotes: "$0!",
      Geekpay: "$49",
      Zimplemoney: "$17",
    },
    {
      Feature: "5 Loans",
      terraNotes: "$25",
      Geekpay: "$49",
      Zimplemoney: "$47",
    },
    {
      Feature: "15 Loans",
      terraNotes: "$45",
      Geekpay: "$99",
      Zimplemoney: "$47",
    },
    {
      Feature: "100 Loans",
      terraNotes: "$95",
      Geekpay: "$169",
      Zimplemoney: "$77",
    },
    {
      Feature: "Unlimited Loans",
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: ["ACH", <br />, "Payment Transaction Fee"],
      FeatureTooltip: [`A Simple, Flat`, <br />, `Per-Transaction Fee`],
      terraNotes: "$.50 to $2.00 / Transaction",
      Geekpay: [`2% + $0.85 / Transaction`, <br />, `& $25 / Month`],
      Zimplemoney: "$4.99 to $18.99 / Transaction",
    },
    {
      Feature: "Loan Setup Fee",
      FeatureTooltip: [`We never charge fees to setup`, <br />, `loans`],
      terraNotes: "$0",
      Geekpay: "$0",
      Zimplemoney: "$33 to $93 / Loan",
    },
  ];
  const featureData = [
    {
      Feature: "Individual ACH Deposits",
      FeatureTooltip: [
        `Each Payment deposits`,
        <br />,
        `individually without removing fees`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Cloud Infrastructure",
      FeatureTooltip: [
        `Entirely built on highly`,
        <br />,
        `secure and resilient serverless`,
        <br />,
        `AWS
        infrastructure`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Custom Loan Tags",
      FeatureTooltip: [
        ` Easily manage and sort your`,
        <br />,
        `loans with custom
        tags`,
      ],
      terraNotes: "Yes",
      Geekpay: "KindOf",
      GeekpayTooltip: "Limited Support",
      Zimplemoney: "No",
    },
    {
      Feature: "Robust Email Follow up",
      FeatureTooltip: [
        `60+ email types so your`,
        <br />,
        `borrower will never be`,
        <br />,
        `able to say they "Forgot"!`,
      ],
      terraNotes: "Yes",
      Geekpay: "KindOf",
      GeekpayTooltip: "Limited Email Follow up",
      Zimplemoney: "Yes",
    },
    {
      Feature: "ACH Refunds",
      FeatureTooltip: [
        `Refund ACH Payments`,
        <br />,
        `to Borrowers for`,
        <br />,
        `any reason`,
      ],
      terraNotes: "Yes",
      Geekpay: "Yes",
      Zimplemoney: "No",
    },
    {
      Feature: "Two Bank Accounts",
      FeatureTooltip: [
        `Easily split Income and`,
        <br />,
        `Expenses between Bank Accounts`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },

    {
      Feature: "Loan Data Downloads",
      FeatureTooltip: [` It's your data`, <br />, `so downloading is easy`],
      terraNotes: "Yes",
      Geekpay: "KindOf",
      GeekpayTooltip: "Limited Loan Data",
      Zimplemoney: "Yes",
    },
    {
      Feature: "Multiple Loans per Borrower",
      FeatureTooltip: [
        `Repeat Buyer? No Sweat.`,
        <br />,
        `Borrowers manage`,
        <br />,
        ` all their loans from`,
        <br />,
        `one login`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: "Borrower Session Recordings",
      FeatureTooltip: [
        ` Gain massive insight`,
        <br />,
        `from Borrower website`,
        <br />,
        `session recordings`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Borrower Assist System",
      FeatureTooltip: [
        ` Help your Borrowers make`,
        <br />,
        `Payments and more`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Backdate Loans",
      FeatureTooltip: [`Easily backdate loans`, <br />, `payments, and fees`],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: "Private Loan Notes",
      FeatureTooltip: [
        `Private per Loan Notes`,
        <br />,
        `shared across your Team`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: "One-Click Payoffs",
      FeatureTooltip: [
        `Everyone can generate`,
        <br />,
        `estimated payoff numbers`,
        <br />,
        `in just one click`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Multiple Borrowers per Loan",
      FeatureTooltip: [`Add up to 5 Borrowers to a Loan`],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: "Balloon Payments",
      FeatureTooltip: [`Add or Edit Balloon`, <br />, `Payments any time`],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Required Payment Override",
      FeatureTooltip: [
        `Manually set required`,
        <br />,
        `payments on a month`,
        <br />,
        `by month basis`,
      ],
      terraNotes: "Yes",
      Geekpay: "KindOf",
      GeekpayTooltip: "Email Support Only",
      Zimplemoney: "No",
    },
    {
      Feature: "Loan Transfers",
      FeatureTooltip: [`Easily Transfer Loans`, <br />, `to another Lender`],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "Yes",
    },
    {
      Feature: "Automated Loan Imports",
      FeatureTooltip: [
        `Importing Loans from other`,
        <br />,
        `systems in easy with
      our`,
        <br />,
        `automated import tool`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Team Member Accounts",
      FeatureTooltip: [
        `Team Members can access`,
        <br />,
        `and manage your loans`,
        <br />,
        `just as you can`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Partner Accounts",
      FeatureTooltip: [
        `An account made for`,
        <br />,
        `that Partner or Lender`,
        <br />,
        `who wants a way`,
        <br />,
        `to keep tabs on`,
        <br />,
        `the details`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: "Automated Partner/Team Payments",
      FeatureTooltip: [
        `Automatically send a`,
        <br />,
        `portion of each`,
        <br />,
        `payment directly to`,
        <br />,
        `your Partner,`,
        <br />,
        `Lender, or Team Member`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
    {
      Feature: ["Borrower Payment", <br />, "Transparency"],
      FeatureTooltip: [
        `Easily view the status`,
        <br />,
        `of your borrower's payment`,
        <br />,
        `account!`,
      ],
      terraNotes: "Yes",
      Geekpay: "No",
      Zimplemoney: "No",
    },
  ];
  return (
    <div style={{ background: "rgb(231, 237, 243" }}>
      <div
        style={{
          backgroundImage: `url("./images/12141.jpg")`, // Corrected the image path
          backgroundSize: "cover",
          padding: "10vh 5vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          boxShadow: "0 0 0 20000px rgba(255, 255, 255, 0.45) inset", // Corrected the boxShadow property
          marginBottom: "-65px", // Corrected the marginBottom property
        }}
      >
        <div className="container  text-center my-5">
          <div className="d-flex h-100 text-center align-items-center my-5">
            <div className="w-100 text-white">
              <h3 className="display-5 text-black">
                {" "}
                What Makes Us Different?
              </h3>
              <p className="text-black fs-4 mt-4">
                How do we compare with our competition?
                <br />
                It's not just that we have better pricing, more features, and
                killer support.
                <br />
                It's that we use the platform every day so mediocrity was never
                an option.
              </p>
            </div>
          </div>
          <div className="card shadow-none">
            <div className="card-header">
              <h2 className="my-3">Pricing</h2>
            </div>
            <div className="card-body p-0 ">
              <div className="table-responsive table-billing-history text-start scrollView overflow-scroll w-full ">
                <table className="table mb-0 fs-5" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th
                        className="border-gray-200 text-center text-white"
                        scope="col"
                        style={{ backgroundColor: "#3a53eb" }}
                      >
                        Features{" "}
                      </th>
                      <th
                        className="border-gray-200 text-center text-white"
                        style={{ width: 200, backgroundColor: "#008E00" }}
                        scope="col"
                      >
                        Terra Notes
                      </th>
                      <th
                        className="border-gray-200 text-center text-white"
                        style={{ width: 200, backgroundColor: "black" }}
                        scope="col"
                      >
                        Geek Pay{" "}
                      </th>
                      <th
                        className="border-gray-200 text-center text-white"
                        style={{ width: 200, backgroundColor: "#662d91" }}
                        scope="col"
                      >
                        Zimplemoney{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricingData.map((row, index) => (
                      <tr key={index} style={{ borderColor: "aliceblue" }}>
                        <td className="text-center align-middle">
                          {row?.Feature}
                          {row?.FeatureTooltip !== undefined && (
                            <>
                              <span
                                data-tip
                                data-for={`pricingData${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <QuestionIcon
                                  className="text-primary mx-2"
                                  size={20}
                                />
                              </span>
                              <ReactTooltip
                                place="TopCenter"
                                id={`pricingData${index}`}
                              >
                                <span>{row?.FeatureTooltip}</span>
                              </ReactTooltip>
                            </>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgba(84, 214, 44, 0.16)",
                          }}
                        >
                          {row?.terraNotes === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />
                            </span>
                          ) : row?.terraNotes === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.terraNotes === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`pricingYKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.terraNotesTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`pricingYKindOf${index}`}
                                >
                                  <span>{row?.terraNotesTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.terraNotes
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgb(0 0 0 / 17%)",
                          }}
                        >
                          {row?.Geekpay === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />
                            </span>
                          ) : row?.Geekpay === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.Geekpay === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`pricingGKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.GeekpayTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`pricingGKindOf${index}`}
                                >
                                  <span>{row?.GeekpayTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.Geekpay
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgb(102 45 145 / 17%)",
                          }}
                        >
                          {row?.Zimplemoney === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />{" "}
                            </span>
                          ) : row?.Zimplemoney === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.Zimplemoney === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`pricingZKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.ZimplemoneyTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`pricingZKindOf${index}`}
                                >
                                  <span>{row?.ZimplemoneyTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.Zimplemoney
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        className="border-gray-200 text-center align-middle"
                        colSpan={5}
                        style={{
                          width: 200,
                          fontSize: "3vh",
                          backgroundColor: "#00000008",
                        }}
                      >
                        <h2 className="my-3">Features</h2>
                      </td>
                    </tr>
                    {featureData.map((row, index) => (
                      <tr key={index} style={{ borderColor: "aliceblue" }}>
                        <td className="text-center align-middle px-2">
                          {row?.Feature}
                          {row?.FeatureTooltip !== undefined && (
                            <>
                              <span
                                data-tip
                                data-for={`featureData${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <QuestionIcon
                                  className="text-primary mx-2"
                                  size={20}
                                />
                              </span>
                              <ReactTooltip
                                place="TopCenter"
                                id={`featureData${index}`}
                              >
                                <span>{row?.FeatureTooltip}</span>
                              </ReactTooltip>
                            </>
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgba(84, 214, 44, 0.16)",
                          }}
                        >
                          {row?.terraNotes === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />{" "}
                            </span>
                          ) : row?.terraNotes === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.terraNotes === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`featureYKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.terraNotesTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`featureYKindOf${index}`}
                                >
                                  <span>{row?.terraNotesTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.terraNotes
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgb(0 0 0 / 17%)",
                          }}
                        >
                          {row?.Geekpay === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />{" "}
                            </span>
                          ) : row?.Geekpay === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.Geekpay === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`featureGKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.GeekpayTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`featureGKindOf${index}`}
                                >
                                  <span>{row?.GeekpayTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.Geekpay
                          )}
                        </td>
                        <td
                          className="text-center align-middle"
                          style={{
                            width: "25%",
                            padding: "0px 0px 10px",
                            backgroundColor: "rgb(102 45 145 / 17%)",
                          }}
                        >
                          {row?.Zimplemoney === "Yes" ? (
                            <span className="badge bg-success text-center">
                              <Icon icon="mingcute:check-fill" color="white" />{" "}
                            </span>
                          ) : row?.Zimplemoney === "No" ? (
                            <span
                              className="badge  text-center"
                              style={{ backgroundColor: "rgb(183, 33, 54)" }}
                            >
                              <Icon icon="maki:cross" color="white" />
                            </span>
                          ) : row?.Zimplemoney === "KindOf" ? (
                            <>
                              <span
                                className="badge bg-warning text-center"
                                data-tip
                                data-for={`featureZKindOf${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon="pepicons-pop:question" />
                              </span>
                              {row?.ZimplemoneyTooltip !== undefined && (
                                <ReactTooltip
                                  place="TopCenter"
                                  id={`featureZKindOf${index}`}
                                >
                                  <span>{row?.ZimplemoneyTooltip}</span>
                                </ReactTooltip>
                              )}
                            </>
                          ) : (
                            row?.Zimplemoney
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
