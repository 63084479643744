import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();

  window.history.scrollRestoration = "manual";

  useEffect(() => {
    window.scrollTo(0, 0);
    const body = document.querySelector("body");

    if (body && body.style.overflow === "hidden") {
      body.style.overflow = "auto";
    }
  }, [pathname]);

  return null;
}
